import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="entgiftung" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Entgiftung`}</h1>
      <h3 {...{
        "id": "entgiftung"
      }}>{`Entgiftung`}</h3>
      <p>{`In jeder Minute, sozusagen bei jedem Atemzug fallen im menschlichen Körper Stoffwechsel-Produkte an, die über Leber, Niere, Darm und Haut ausgeschieden werden müssen.`}</p>
      <p>{`Häufig kommt es jedoch durch Umweltbelastungen, falsche Ernährungsgewohnheiten oder latent vorhandene Organschwächen zu Ansammlungen von Schlackenstoffen, die der Körper nicht mehr ausreichend ausscheiden kann und irgendwo im Bindegewebe zunächst zwischenlagert.`}</p>
      <p>{`Über Jahre können diese „Zwischenlager“ zu reinsten Deponien werden, die die notwendigen Zellfunktionen blockieren.`}</p>
      <p>{`Hier ist es erforderlich, auf allen Ebenen den Stoffwechsel durch entgiftende und ausleitende Therapien wieder in Gang zu bringen. Dazu gehören homöopathische und pflanzliche Medikamente ebenso wie`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/therapien/darmsanierung"
          }}>{`Darmsanierung`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/therapien/schroepfmassage"
          }}>{`Schröpfmassage`}</a></li>
      </ul>
      <p>{`Nur ein gesundes und starkes Immunsystem ist in der Lage, den täglichen Abwehrkampf optimal zu bestehen und den Körper vor Mutationen und bösartigen Zellveränderungen zu schützen (Vorsorge / Prävention). Auch im Rahmen der Tumornachsorge ist die Immunstärkung von grundlegender Bedeutung.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      